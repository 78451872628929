import antecipame from './antecipame';
import cely from './cely';
import dasa from './dasa';
import espacosaude from './espacosaude';
import hummanamais from './hummanamais';
import inclua from './inclua';
import medsimples from './medsimples';

export default {
  medsimples,
  antecipame,
  hummanamais,
  inclua,
  cely,
  espacosaude,
  dasa,
};
