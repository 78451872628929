import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Flex,
  type FlexProps,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import Image from 'next/image';
import { useMemo } from 'react';
import { useAuth } from '../../providers/auth_provider_client';
import { useTenantConfig } from '../../providers/tenant_provider';

interface UserInfoProps extends FlexProps {
  onOpen: () => void;
}
export const UserInfo = ({ onOpen }: UserInfoProps) => {
  const auth = useAuth();
  const userName = useMemo(() => {
    return auth.user?.name && auth.user?.name.split(' ').length > 1
      ? `${auth.user?.name.split(' ').shift()} ${auth.user?.name
          .split(' ')
          .pop()}`
      : auth.user?.name;
  }, [auth.user?.name]);
  const userPicture = useMemo(() => {
    return auth.user?.picture ?? '';
  }, [auth.user?.picture]);

  const logout = auth.onLogout
    ? async () => {
        await auth.onLogout();
      }
    : () => {
        window.location.href = auth.logoutURL;
      };

  const config = useTenantConfig();
  return (
    <Flex
      paddingX={{ base: 4, md: 4 }}
      height={20}
      alignItems='center'
      background='white'
      justifyContent={'space-between'}
    >
      <>
        <Box minWidth={{ base: '100px', md: '240px' }}>
          <IconButton
            onClick={onOpen}
            variant='outline'
            aria-label='open menu'
            icon={<HamburgerIcon />}
          />
        </Box>

        <Box
          position={'absolute'}
          left={'50%'}
          transform={'translateX(-50%)'}
          maxW={160}
        >
          <Image src={config.logo} alt='logo' width={160} height={200} />
        </Box>

        <HStack spacing={{ base: '0', md: '6' }}>
          {userName ? (
            <Flex alignItems={'center'}>
              <Menu>
                <MenuButton
                  paddingY={2}
                  transition='all 0.3s'
                  _focus={{ boxShadow: 'none' }}
                >
                  <HStack>
                    <Avatar size={'md'} marginRight={2} src={userPicture} />
                    <VStack
                      display={{ base: 'none', md: 'flex' }}
                      alignItems='flex-start'
                      spacing={0}
                      marginLeft={2}
                    >
                      <Text fontSize='sm' fontWeight={500}>
                        Bem vindo
                      </Text>
                      <Text fontSize='xs' color='gray.600'>
                        {userName}
                      </Text>
                    </VStack>
                  </HStack>
                </MenuButton>
                <MenuList bg='white'>
                  <MenuItem onClick={logout}>Sair</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          ) : null}
        </HStack>
      </>
    </Flex>
  );
};
