const intlDate = new Intl.DateTimeFormat('pt-BR', {
  dateStyle: 'short',
  timeStyle: 'medium',
});

/**
 * Formats a Date object to brazillian format of date and time.
 * @param date  Date to be formated
 * @param separator Text to be placed between date and time. Defaults to `' às '`
 * @returns String containing the formatted date
 * @example dateTimeToBR(new Date('2000-05-05T12:12:12Z')) // => 01/01/2000 às 09:12:12
 */
export const dateTimeToBR = (date: Date, separator = ' às ') => {
  return intlDate.format(date).replace(', ', separator);
};

/**
 * Parse a string from datetime format to brazilian time format. (e.g. "19:10")
 * @param dateTime
 * @returns
 */
export const parseTime = (dateTime: string) => {
  const dateObject = new Date(dateTime);
  const hours = String(dateObject.getUTCHours()).padStart(2, '0');
  const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

/**
 * Parse the date for brazilian contracts. (e.g. "18 de Dezembro de 2009")
 * @param dateTime
 * @returns
 */
export const parseDateForContract = (dateTime: string) => {
  if (!dateTime) return '';
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const [yyyy, mm, dd] = dateTime.split('T')[0].split('-');

  return `${dd} de ${months[Number(mm) - 1]} de ${yyyy}`.toUpperCase();
};

/**
 * Convert datetime string to unix time
 * @param dateTime
 * @returns
 */
export const convertDateToUnix = (dateTime: string) => {
  return !Number.isNaN(new Date(dateTime))
    ? Math.floor(new Date(dateTime).getTime() / 1000)
    : null;
};
